<template>
  <v-app>
    <v-main>
      <v-container fluid >
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <form ref="form" @submit.prevent="isRegister ? register() : login()">
                  <v-text-field v-model="firstname" v-if="isRegister" name="firstname" label="Firstname" type="text" placeholder="firstname" required></v-text-field>
                  <v-text-field v-model="lastname" v-if="isRegister" name="lastname" label="Lastname" type="text" placeholder="lastname" required></v-text-field>
                  <v-text-field v-model="email" name="email" label="email" type="text" placeholder="email address" required></v-text-field>
                  <v-text-field v-model="password" name="password" label="Password" type="password" placeholder="password" required></v-text-field>
                  <v-text-field v-if="isRegister" v-model="confirmPassword" name="confirmPassword" label="Confirm Password" type="password" placeholder="cocnfirm password" required></v-text-field>
                  <div :style="{color:messageColor}"> {{errorMessage}}</div>
                  <v-btn type="submit" class="mt-4" color="primary" value="log in">{{isRegister ? stateObj.register.name : stateObj.login.name}}</v-btn>
                  <div class="grey--text mt-4" style="cursor:pointer" v-on:click="isRegister = !isRegister;">
                    {{toggleMessage}}  
                  </div>
                </form>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>
 
<script>
  import axios from "axios";

  export default {
    name: "App",
    data() {
      return {
        firstname: "",
        lastname: "",
        email: "",
        password: "",
        confirmPassword: "",
        isRegister : false,
        errorMessage: "",
        messageColor: '',
        stateObj: {
          register :{
            name: 'Register',
            message: 'Aleady have an Acoount? login.'
          },
          login : {
            name: 'Login',
            message: 'Register'
          }
        }
      };
    },
    methods: {
      login() {
        var data = {"email": this.email, "password": this.password}
        axios({method:'post', url: process.env.VUE_APP_API_Endpoint + "/users/login", data: data})
        .then(response =>{
          this.$store.state.user = response.data.data
          this.$router.push("/dashboard")
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.message){
            this.setErrorMessage(error.response.data.message);
          }
          else{
            console.log('login error',error)
          }
        })
      },
      register() {
        if(this.password != this.confirmPassword){
          this.setErrorMessage("password did not match");
        }
        let data = {};
        data.email = this.email;
        data.password = this.password;
        data.firstname = this.firstname;
        data.lastname = this.lastname;
        axios({method:'post', url: process.env.VUE_APP_API_Endpoint + "/users/register", data: data})
        .then(response =>{
          this.isRegister = false;
          this.errorMessage = "";
          this.$refs.form.reset();
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.message){
            this.setErrorMessage(error.response.data.message);
          }
          else{
            console.log('register error',error)
          }
        })
      },
      setErrorMessage(msg, color){
        this.errorMessage = msg;
        if (color){
          this.messageColor = color
        }
        else{
          this.messageColor = 'red'
        }
        setTimeout(()=>{
        this.errorMessage = '';
      }, 10000);
      }
    },
    computed: {
      toggleMessage : function() { 
        return this.isRegister ? this.stateObj.register.message : this.stateObj.login.message 
      }
    }
 };
 </script>