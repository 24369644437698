<template>
  <div>
    <v-row no-gutters justify="start">
      <v-card style="margin:5px;"> 
        <video ref="input_video" width="0"></video>
        <canvas class="output_canvas" ref="output_canvas" :width="width" :height="height" ></canvas>
        <v-card>
          <v-card-text>
            <v-row>
              <v-card class="pa-2" cols="auto" outlined tile>
                <v-chip color="primary" text-color="white" x-large label title="Selected hand">
                  <v-avatar left>
                    <v-icon>mdi-swap-horizontal-bold</v-icon>
                  </v-avatar>
                  <span style="width:50px;">{{handSide}}</span>
                </v-chip>
              </v-card>
              <v-card class="pa-2" cols="auto" outlined tile>
                <v-chip :color="lastHandPosColor" text-color="white" x-large label title="hand position">
                  <v-avatar left>
                    <v-icon>mdi-state-machine</v-icon>
                  </v-avatar>
                  <span style="width:50px;">{{lastHandPos}}</span>
                </v-chip>
              </v-card>
              <v-card class="pa-2" cols="auto" outlined tile v-show="lastHandPos=='open'" title="times the selected hand opened">
                <v-chip color="teal" text-color="white" x-large label>
                  <v-avatar left>
                    <v-icon>mdi-hand-back-left</v-icon>
                  </v-avatar>
                  <span style="width:25px;">{{numberOfOpen}}</span>
                </v-chip>
              </v-card>
              <v-card class="pa-2" cols="auto" outlined tile v-show="lastHandPos=='close'" title="times the selected hand closed">
                <v-chip color="#64B5F6" text-color="white" x-large label>
                  <v-avatar left>
                    <v-icon>mdi-hand-okay</v-icon>
                  </v-avatar>
                  <span style="width:25px;">{{numberOfClose}}</span>
                </v-chip>
              </v-card>
              <v-spacer />
              <v-card class="pa-2" cols="auto" outlined tile title="save activity data" v-show="numberOfClose >= minimumCountToSave">
                <v-chip color="#64B5F6" text-color="white" x-large label @click="saveExercise">
                  <v-avatar>
                    <v-icon>mdi-content-save</v-icon>
                  </v-avatar>
                </v-chip>
              </v-card>
            </v-row>
          </v-card-text>
        </v-card>
      </v-card>
      <v-card :style="{'width':width + 'px', 'margin':'5px'}">
        <v-card outlined tile>
          <div class="container">
            <v-card flat color="lightgrey">
              <span v-show="!autoConfigure" @click="loadDefaultFingerSettings(true)" class="float-right" title="load default settings"><v-icon style="color:dodgerblue">mdi-cog-refresh-outline</v-icon></span>  
              <v-switch v-model="autoConfigure" label="auto configure" hide-details @change="autoSwitchChanged" style="margin:0;"></v-switch>
              <v-range-slider v-model="openCloseIndicator" v-if="!autoConfigure" min="-5" max="185" :tick-labels="openCloseLabels" :thumb-size="35" thumb-label></v-range-slider>
              <div v-show="!autoConfigure">
                <v-slider label="stability sensitivity" v-model="stabilitySensitivity" min="10" max="60" step="5" :tick-labels="sensitivityTicksLabels" :thumb-size="25" thumb-label></v-slider>
                <v-slider label="stability check rate" v-model="stabilityCheckMiliSecs" min="50" max="550" step="50" :tick-labels="stabilityTicksLabels" ticks="always" :thumb-size="25" thumb-label></v-slider>
                <v-slider label="minimum to save" v-model="minimumCountToSave" min="10" max="100" step="5" ticks="always" :thumb-size="25" thumb-label></v-slider>
              </div>
              <v-switch v-model="showJointAngles" :label="'Select Specific Fingerr (' + fingers[selctedFinger].name + ')'" hide-details style="margin:0;"></v-switch>
              <v-container class="pa-0" fluid v-show="showJointAngles">
                <v-radio-group v-model="selctedFinger" hide-details style="margin: 10px;" @change="resetTracking">
                  <v-radio
                    v-for="f in fingers"
                    :key="f.name"
                    :label="f.name"
                    :value="f.id"
                    @click="setSelectedFinger(f)"
                  ></v-radio>
                </v-radio-group>
              </v-container>
            </v-card>
            <span v-show="settingsChanged && !autoConfigure" @click="saveSettings" class="float-right" title="save changes"><v-icon style="color:dodgerblue">mdi-content-save</v-icon></span>  
            
            <div v-show="!autoConfigure">
              <div>time spent: {{timeSpent}}</div>
              <div>average time to close and open: {{averageOpenCloseTime}}</div>
              <div>stability: {{stabilityMeasure}}</div>
              <div>fps: {{fps}}</div>
              <div>hand offset: {{handOffset}}</div>
            </div>
          </div>
        </v-card>
      </v-card>
    </v-row>
    <v-row no-gutters justify="start" :style="{color:messageColor}">
      {{errorMessage}}
    </v-row>
  </div>
</template>

<script >
  import { Hands, HAND_CONNECTIONS } from "@mediapipe/hands";
  import { Camera } from "@mediapipe/camera_utils";
  import { drawConnectors, drawLandmarks } from "@mediapipe/drawing_utils";
  import axios from "axios";
  import moment from "moment"
  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

  export default {
    name: "HandModel",
    data: function() {
      return {
        camera: null,
        number: null,
        ctx: null,
        width: '640',
        height: '480',
        handSide: '',
        defaultFingerSettings: [
          {id: 0, name: 'Full Hand', joints: [], anglePoints:[] , selected: true, defauldRange:[115,165]},
          {id: 1, name: 'Thumb', joints: [1,2,3], anglePoints:[[0,1,2], [1,2,3], [2,3,4]], angles:[], selected: true, defauldRange:[150, 165]},
          {id: 2, name: 'Index Finger', joints: [5,6,7], anglePoints:[[0,5,6], [5,6,7], [6,7,8]], angles:[], selected: true, defauldRange:[115, 165]},
          {id: 3, name: 'Middle Finger', joints: [9,10,11], anglePoints:[[0,9,10], [9,10,11], [10,11,12]], angles:[], selected: true, defauldRange:[115,165]},
          {id: 4, name: 'Ring Finger', joints: [13,14,15], anglePoints:[[0,13,14], [13,14,15], [14,15,16]], angles:[], selected: true, defauldRange:[115,160]},
          {id: 5, name: 'Pinky', joints: [17,18,19], anglePoints:[[0,17,18], [17,18,19], [18,19,20]], angles:[], selected: true, defauldRange:[115,166]},
        ],
        fingers: [
        ],
        selectetJointsAngleSum: 0,
        averageAngle: 0,
        showAngleSumDetailsSwitch: false,
        startTime: new Date(),
        endTime: new Date(),
        lastHandPos: '',
        numberOfOpen : 0,
        numberOfClose : 0,
        showJointAngles: false,
        myImage: null,
        stableCntr: 0,
        unstableCntr: 0,
        defaultStabilityCheckMiliSecs: 300,
        defaultStabilitySensitivity: 35,
        defaultMinimumCountToSave: 10,
        stabilityCheckMiliSecs: 50,
        stabilitySensitivity: 10,
        minimumCountToSave: 10,
        stabilityLastGoodNumber: 0,
        stabilityHandPoseChangeNumber: 0,
        lastStabilityCheckDate: new Date(),
        stopCountingAfter: 0,
        firstFpsDate: new Date(),
        fps: 0,
        framesCntr: 0,
        selctedFinger: 0,
        openTicksLabels: ['easy',,,,,'medium',,,,,'hard'],
        closeTicksLabels: ['easy',,,,,'medium',,,,,'hard'],
        sensitivityTicksLabels: ['more',,,,,,,,,,'less'],
        stabilityTicksLabels: ['faster',,,,,,,,,,'slower'],
        openCloseLabels: ['close',,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,'open'],
        autoConfigure: false,
        openCloseIndicator: [0,0],
        settingsChanged: false,
        errorMessage: '',
        messageColor: '',
        lastFrameCalsDate: new Date(),
        handOffset: 0
      };
    },
    computed: {
      inputVideo() {
        return this.$refs.input_video;
      },
      stabilityMeasure(){
        if (this.stableCntr + this.unstableCntr > 0){
          return (100 - (Math.round((this.unstableCntr / (this.stableCntr + this.unstableCntr))*100))) / 10;
        }
        return 0;
      },
      lastHandPosColor(){
        if (this.lastHandPos == 'open'){
          return 'teal';
        }
        else if (this.lastHandPos == 'close'){
          return '#64B5F6';
        }
      },
      averageOpenCloseTime(){
        if (this.numberOfOpen + this.numberOfClose > 0){
          return Math.round((this.endTime - this.startTime)/(this.numberOfOpen + this.numberOfClose)/10)/100*2
        }
        return 0;
      },
      timeSpent(){
        if (this.endTime > this.startTime){
          return Math.round((this.endTime - this.startTime)/1000)
        }
        return 0
      },
      currentPage(){
        return this.$store.state.currentPage
      }
    },
    beforeMount(){
      this.loadDefaultFingerSettings(false);

      if (this.$store.state.user.settings){
        this.stabilitySensitivity = this.$store.state.user.settings.stabilitySensitivity;
        this.stabilityCheckMiliSecs = this.$store.state.user.settings.stabilityCheckMiliSecs;
        this.minimumCountToSave = this.$store.state.user.settings.minimumCountToSave;

        if (this.$store.state.user.settings.fingerSettings){
          for (let i = 0; i<this.$store.state.user.settings.fingerSettings.length; i++){
            if (this.fingers.length > i){
              this.fingers[i].joints = this.$store.state.user.settings.fingerSettings[i].joints;
              this.fingers[i].defauldRange = this.$store.state.user.settings.fingerSettings[i].defauldRange;
            }
            else{
              this.fingers.push(
                {
                  id: i, 
                  name: this.$store.state.user.settings.fingerSettings[i].name, 
                  joints: this.$store.state.user.settings.fingerSettings[i].joints, 
                  anglePoints:this.$store.state.user.settings.fingerSettings[i].anglePoints, 
                  angles:[], 
                  selected: false, 
                  defauldRange:this.$store.state.user.settings.fingerSettings[i].defauldRange
                }
              )
            }
          }
        }
        this.settingsChanged = false;
      }
    },
    mounted() {
      this.ctx = this.$refs.output_canvas.getContext("2d");

      this.init();
      setTimeout(()=>{
        this.settingsChanged = false;
      }, 1);
    },
    methods: {
      init() {
        const hands = new Hands({
          locateFile: (file) => {
            return `https://cdn.jsdelivr.net/npm/@mediapipe/hands/${file}`;
          },
        });
        hands.setOptions({
          maxNumHands: 1,
          minDetectionConfidence: 0.5,
          minTrackingConfidence: 0.5,
          modelComplexity: 1,
          selfieMode: true
        });
        hands.onResults(this.onResults);
  
        this.camera = new Camera(this.inputVideo, {
          onFrame: async () => {
            await hands.send({ image: this.inputVideo });
          },
        });
        this.camera.start();

        this.ctx.font = "12px Georgia";
        let windowWidth = window.innerWidth - 107
        if (windowWidth/this.width > window.innerHeight/this.height){
          if (window.innerHeight < this.height){
            let mltp = window.innerHeight/this.height;
            this.width *= mltp;
            this.height *= mltp;
          }
        }
        else{
          if (windowWidth < this.width){
            let mltp = windowWidth/this.width;
            this.width *= mltp;
            this.height *= mltp;
          }
        }
      },
      onResults(results) {
        this.ctx.save();
        this.ctx.clearRect(0, 0, results.image.width, results.image.height);
        this.ctx.drawImage(
          results.image,
          0,
          0,
          results.image.width,
          results.image.height,
          0,
          0,
          this.width,
          this.height
        );

        this.findHands(results, true, false);
        this.ctx.restore();
      },
      findHands(results, drawConns = true, drawLms = true) {
        let dateNow = new Date();
        if (this.framesCntr == 0){
          this.firstFpsDate = dateNow;
        }
        else{
          this.fps = Math.round(this.framesCntr / ((dateNow - this.firstFpsDate)/1000));
        }
        this.framesCntr++;
        if (results.multiHandLandmarks) {
          var indx = 0;
          for (const landmarks of results.multiHandLandmarks) {
            if ((results.multiHandedness) && results.multiHandedness[indx].score > 0.9){
              this.handSide = results.multiHandedness[indx].label;
              if (drawConns){
                drawConnectors(this.ctx, landmarks, HAND_CONNECTIONS, {
                  color: "#00FF00",
                  lineWidth: 2,
                });
              }
              if (drawLms) {
                drawLandmarks(this.ctx, landmarks, {
                  color: "#FF0000",
                  lineWidth: 2
                });
              }
              let numberOfAngles = this.calcAngle(landmarks);
              let mltp = numberOfAngles / 15;
              this.averageAngle = this.selectetJointsAngleSum / numberOfAngles;

              if (this.autoConfigure){
                if (this.openCloseIndicator[0] < 0 || this.averageAngle < this.openCloseIndicator[0]){
                  this.openCloseIndicator[0] = this.averageAngle;
                }
                if (this.openCloseIndicator[1] > 180 || this.averageAngle > this.openCloseIndicator[1]){
                  this.openCloseIndicator[1] = this.averageAngle;
                }
                return;
              }
              let min = 9999;
              let max = -9999;
              let round = 0;
              [5,9,13,17].forEach(i => {
                round = Math.round(landmarks[i].z * 1000);
                if (round > max){
                  max = round;
                }
                if (round < min){
                  min = round;
                }
              });
              this.handOffset = max - min;
              if (this.lastHandPos == 'close'){
                if (this.averageAngle > this.openCloseIndicator[1]){
                  this.lastHandPos = 'open';
                  this.numberOfOpen ++;
                  if (this.stopCountingAfter > 0 && this.numberOfOpen >= this.stopCountingAfter){
                    this.camera.stop();
                    return;
                  }
                  this.endTime = dateNow;
                  this.stabilityHandPoseChangeNumber = this.selectetJointsAngleSum;
                }
                else if (this.selectetJointsAngleSum > this.stabilityHandPoseChangeNumber){
                  if (dateNow - this.lastStabilityCheckDate > this.stabilityCheckMiliSecs){
                    if (this.selectetJointsAngleSum - this.stabilityLastGoodNumber < -this.stabilitySensitivity){
                      this.unstableCntr++;
                    }
                    else{
                      this.stableCntr++;
                    }
                    this.lastStabilityCheckDate = dateNow;
                  }
                }
                if (this.selectetJointsAngleSum > this.stabilityLastGoodNumber){
                  this.stabilityLastGoodNumber = this.selectetJointsAngleSum;
                }
              }
              else if (this.lastHandPos == 'open'){
                if (this.averageAngle < this.openCloseIndicator[0]){
                  this.lastHandPos = 'close';
                  this.numberOfClose ++;
                  this.endTime = dateNow;
                  this.stabilityHandPoseChangeNumber = this.selectetJointsAngleSum;
                }
                else if (this.selectetJointsAngleSum < this.stabilityHandPoseChangeNumber){
                  if (dateNow - this.lastStabilityCheckDate > this.stabilityCheckMiliSecs){
                    if (this.stabilityLastGoodNumber - this.selectetJointsAngleSum < -this.stabilitySensitivity){
                      this.unstableCntr++;
                    }
                    else {
                      this.stableCntr++;
                    }
                    this.lastStabilityCheckDate = dateNow;
                  }
                }
                if (this.selectetJointsAngleSum < this.stabilityLastGoodNumber){
                  this.stabilityLastGoodNumber = this.selectetJointsAngleSum;
                }
              }
              else{
                if (this.averageAngle < this.openCloseIndicator[0]){
                  this.startTime = dateNow;
                  this.lastHandPos = 'close';
                  this.stabilityLastGoodNumber = this.selectetJointsAngleSum;
                  this.stabilityHandPoseChangeNumber = this.selectetJointsAngleSum;
                }
                else if (this.averageAngle > this.openCloseIndicator[1]){
                  this.startTime = dateNow;
                  this.lastHandPos = 'open';
                  this.stabilityLastGoodNumber = this.selectetJointsAngleSum;
                  this.stabilityHandPoseChangeNumber = this.selectetJointsAngleSum;
                }
              }
            }
            indx++;
          }
        }
      },
      calcAngle (landmarks){
        var a,b,c,angle,totalAngles=0,numberOfAngles=0;

        for (let index = 1; index < this.fingers.length; index++){
          this.fingers[index].angles = [];
          if (this.fingers[index].selected){
            this.fingers[index].anglePoints.forEach(lm => {
              a = landmarks[lm[0]];
              b = landmarks[lm[1]];
              c = landmarks[lm[2]];
              angle = Math.round(this.angle_triangle(b.x, a.x, c.x, b.y, a.y, c.y, b.z, a.z, c.z));
              if (this.showJointAngles ){
                this.ctx.strokeText(angle + ' ', b.x*this.width, b.y*this.height);
              }
              totalAngles += angle;
              this.fingers[index].angles.push(angle);
              numberOfAngles++;
            });
          }
        }
        this.selectetJointsAngleSum = totalAngles;
        return numberOfAngles;
      },
      angle_triangle(x1,x2,x3,y1,y2,y3,z1,z2,z3)
      {
        let num = (x2-x1)*(x3-x1)+(y2-y1)*(y3-y1)+(z2-z1)*(z3-z1) ;
        
        let den = Math.sqrt(Math.pow((x2-x1),2)+
                    Math.pow((y2-y1),2)+Math.pow((z2-z1),2))*
                    Math.sqrt(Math.pow((x3-x1),2)+
                    Math.pow((y3-y1),2)+Math.pow((z3-z1),2)) ;
        
        let angle = Math.acos(num / den)*(180.0/3.141592653589793238463) ;
        
        return angle ;
      },
      setSelectedFinger: function(f){
        if (f.id == 0){
          for (let index = 0; index < 6; index++) {
            this.fingers[index].selected = true;
          }
        }
        else{
          f.selected = true;
          for (let index = 0; index < this.fingers.length; index++) {
            if (this.fingers[index].id != f.id){
              this.fingers[index].selected = false;
            }
          }
        }
        this.resetTracking();
        this.openCloseIndicator = f.defauldRange;
      },
      resetTracking(){
        this.selectetJointsAngleSum = 0;
        this.startTime = null;
        this.endTime = null;
        this.lastHandPos = '';
        this.numberOfClose = 0;
        this.numberOfOpen = 0;
        this.stableCntr = 0;
        this.unstableCntr = 0;
        this.stabilityLastGoodNumber = 0;
        this.stabilityHandPoseChangeNumber = 0;
        this.lastStabilityCheckDate = new Date();
        this.firstFpsDate = new Date();
        this.fps = 0;
        this.framesCntr = 0;
        if (this.autoConfigure){
          this.openCloseIndicator = [-5, 185]
        }
      },
      autoSwitchChanged(){
        if (this.autoConfigure){
          this.resetTracking();
        }
        else{
          if (this.openCloseIndicator[0] > 0){
            this.openCloseIndicator = [Math.round(this.openCloseIndicator[0]*1.1), Math.round(this.openCloseIndicator[1]*0.95)]
          }
          else{
            this.openCloseIndicator = this.fingers[this.selctedFinger].defauldRange;
          }
          this.settingsChanged = true;
          this.fingers[this.selctedFinger].defauldRange = this.openCloseIndicator;
        }
      },
      saveExercise(){
        if (!this.handSide || this.handSide == ''){
          return;
        }
        try {
          let activity = {
            "date": moment(),
            "hand": this.handSide,
            "closedCount": this.numberOfClose,
            "closeIndicator": this.openCloseIndicator[0],
            "openIndicator": this.openCloseIndicator[1],
            "stabilitySensitivity": this.stabilitySensitivity,
            "stabilityCheckRate": this.stabilityCheckMiliSecs,
            "handPose": this.handPose,
            "duration": this.timeSpent,
            "averageOpenCloseTime": this.averageOpenCloseTime,
            "stability": this.stabilityMeasure,
            "fps": this.fps,
            "selctedFinger": this.fingers[this.selctedFinger].name
          }
          axios({method:'post', url: process.env.VUE_APP_API_Endpoint + "/users/exercise/" + this.$store.state.user._id + "/" + this.$store.state.user.sessionId, data: activity})
          .then(response =>{
            this.setErrorMessage("Your activity data is saved!", 'green');

            this.resetTracking();
          })
          .catch(error => {
            this.setErrorMessage(error);
            console.log('saveExercise error 1',error)
          })
        } catch (error) {
          this.setErrorMessage(error);
          console.log('saveExercise error 2',error)
        }
      },
      saveSettings: function(){
        try {
          let settings = {
            "fingerSettings": [],
            "stabilitySensitivity": this.stabilitySensitivity,
            "stabilityCheckMiliSecs": this.stabilityCheckMiliSecs,
            "minimumCountToSave": this.minimumCountToSave
          }
          for (let i=0; i<this.fingers.length; i++){
            settings.fingerSettings.push({
              id: this.fingers[i].id,
              name: this.fingers[i].name,
              anglePoints: this.fingers[i].anglePoints,
              defauldRange: this.fingers[i].defauldRange,
              joints: this.fingers[i].joints
            })
          }
          axios({method:'post', url: process.env.VUE_APP_API_Endpoint + "/users/updatesettings/" + this.$store.state.user._id + "/" + this.$store.state.user.sessionId, data: settings})
          .then(response =>{
            this.settingsChanged = false;
            this.setErrorMessage("Your settings is saved!", 'green');
          })
          .catch(error => {
            this.setErrorMessage(error);
            console.log('saveSettings error 1',error)
          })
        } catch (error) {
          this.setErrorMessage(error);
          console.log('saveSettings error 2',error)
        }
      },
      loadDefaultFingerSettings(changed){
        this.fingers = JSON.parse(JSON.stringify(this.defaultFingerSettings));
        this.stabilitySensitivity = this.defaultStabilitySensitivity;
        this.stabilityCheckMiliSecs = this.defaultStabilityCheckMiliSecs;
        this.minimumCountToSave = this.defaultMinimumCountToSave;
        this.selctedFinger = 0;
        this.openCloseIndicator = this.fingers[this.selctedFinger].defauldRange;
        this.settingsChanged = changed;
      },
      setErrorMessage(msg, color){
        this.errorMessage = msg;
        if (color){
          this.messageColor = color
        }
        else{
          this.messageColor = 'red'
        }
        setTimeout(()=>{
        this.errorMessage = '';
      }, 10000);
      }
    },
    watch:{
      handSide: function(val){
        if (val && val != ''){
          this.resetTracking();
        }
      },
      selctedFinger: function(){
        this.resetTracking();
      },
      currentPage: function(val){
        this.camera.stop();
      },
      openCloseIndicator: function(val){
        if (this.autoConfigure){
          return;
        }
        this.resetTracking();
        this.settingsChanged = true;
        this.fingers[this.selctedFinger].defauldRange = this.openCloseIndicator;
      },
      stabilitySensitivity: function(val){
        this.resetTracking();
        this.settingsChanged = true;
      },
      stabilityCheckMiliSecs: function(val){
        this.resetTracking();
        this.settingsChanged = true;
      },
      minimumCountToSave: function(val){
        this.resetTracking();
        this.settingsChanged = true;
      }
    }
  };
</script>
