<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" :mini-variant="$store.state.miniMenu" permanent expand-on-hover>
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>

        <v-list-item-title>{{userInfo}}</v-list-item-title>

        <!-- <v-btn icon>
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn> -->
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          @click.prevent="openpage(item.route)"
          link
        >
          <v-list-item-icon>
            <v-icon @click.prevent="openpage(item.route)">{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app style="height:56px">
      <v-list-item-avatar>
          <v-img src="./../public/images/logo.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>Hand Tracking</v-list-item-title>
    </v-app-bar>

    <!-- Sizes your content based upon application components -->
    <v-main style="padding:60px 0 12px 56px;overflow-x: auto;">

      <!-- Provides the application the proper gutter -->
      <v-container fluid>

        <!-- If using vue-router -->
        <router-view></router-view>
      </v-container>
    </v-main>

    <v-footer app>
      <!-- -->
    </v-footer>
  </v-app>

</template>
<!-- <template>
  <v-app>
    <v-app-bar
      app
      color="default"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Hand Tracking"
          class="shrink mr-2"
          contain
          src="./assets/img/logo.png"
          transition="scale-transition"
          width="40"
        />
        <span>Hand Tracking</span>
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href=""
        target="_blank"
        text
      >
        <span class="mr-2">login</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template> -->

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: true,
    // items: [
    //   { title: 'Track', icon: 'mdi-home-city', route: '/'},
    //   { title: 'Activity Report', icon: 'mdi-chart-line', route: '/dashboard'},
    //   { title: 'About', icon: 'mdi-information-variant', route: '/about'},
    //   { title: 'Logout', icon: 'mdi-logout', route: '/logout'},
    // ]
  }),
  methods:{
    openpage: function(route){
      this.$store.state.miniMenu = true;
      if(this.$router.currentRoute.fullPath != route){
        this.$store.state.currentPage = route
        const _this = this;
        _this.$router.push(route)
      };
    }
  },
  computed:{
    items(){
      if (this.$store.state.user._id){
        return [
        { title: 'Track', icon: 'mdi-home-city', route: '/'},
        { title: 'Activity Report', icon: 'mdi-chart-line', route: '/dashboard'},
        { title: 'About', icon: 'mdi-information-variant', route: '/about'},
        { title: 'Logout', icon: 'mdi-logout', route: '/logout'},
      ]
      }
      else{
        return [];
      }
    },
    userInfo(){
      if (this.$store.state.user._id){
        return this.$store.state.user.firstname + ' ' + this.$store.state.user.lastname
      }
      return "please login!"
    }
  }
};
</script>

<style>
  .v-input--selection-controls__input + .v-label{
    font-size: 14px;
  }
  .v-icon.v-icon{
    font-size: 18px;
  }
  .v-application--is-ltr .v-input--selection-controls__input{
    margin-right: 3px;
  }
  .v-input--selection-controls__ripple {
    height: 24px;
    width: 24px;
    left: -7px;
    top: calc(50% - 19px);
    margin: 3px;
  }
  .v-input--radio-group.v-input--radio-group--row .v-radio {
    margin-right: 10px;
  }
  .v-input--radio-group--column .v-radio:not(:last-child):not(:only-child) {
    margin-bottom: 2px;
  }
</style>