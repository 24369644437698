import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    currentPage: "/",
    miniMenu: true,
    user: {
      _id: "",
      firstname: "",
      lastname: ""
    },
    dashboardData: null,
    doctor:{
      _doctorId: ""
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
