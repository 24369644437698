import Vue from 'vue'
import VueRouter from 'vue-router'
import MediapipeHand from '../components/MediapipeHand.vue'
import Login from '../components/login.vue'
import store from './../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'hand Tracking',
    component: MediapipeHand,
    beforeEnter: (to, from, next) => {
      if (store.state.user._id){
        next()
      }
      else{
        next({path: "/login"})
      }
    }
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../components/dashboard.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user._id){
        next()
      }
      else{
        next({path: "/login"})
      }
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue'),
    beforeEnter: (to, from, next) => {
      if (store.state.user._id){
        next()
      }
      else{
        next({path: "/login"})
      }
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/logout',
    name: 'logout',
    component: Login,
    beforeEnter: (to, from, next) => {
      store.state.user = {
        _id: "",
        firstname: "",
        lastname: ""
      };
      store.state.dashboardData = null
      next({path: "/login"})
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
